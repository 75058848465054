<template>
  <section class="quotation-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row class="quotation-add">
        <!-- Col: Left (Quotation Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <b-card no-body class="quotation-preview-card">
                <!-- Header -->
                <b-card-body class="quotation-padding pb-0">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      flex-md-row flex-column
                      quotation-spacing
                      mt-0
                    "
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper mb-0">
                        <h3
                          class="text-primary quotation-logo ml-0"
                          style="font-size: 22px"
                        >
                          {{ quotationData.company_name_en }}
                          <br />
                          {{ quotationData.company_name_cn }}
                        </h3>
                      </div>
                      <b-card-text class="mb-0 logo-address">
                        {{ quotationData.company_address_en }}
                      </b-card-text>
                      <b-card-text class="mb-0 logo-address">
                        {{ quotationData.company_address_cn }}
                      </b-card-text>
                      <b-card-text class="mb-0 logo-address">
                        <span v-if="quotationData.company_tel"
                          >Tel. 電話： {{ quotationData.company_tel }}</span
                        ><span class="ml-1" v-if="quotationData.fax"
                          >Fax 圖文傳真：{{ quotationData.fax }}</span
                        ><span class="ml-1" v-if="quotationData.company_email">
                          Email 電郵：{{ quotationData.company_email }}</span
                        >
                      </b-card-text>
                    </div>

                    <!-- Header: Right Content 
                                
                                -->
                  </div>
                  <b-row>
                    <!-- Header: Left Content -->
                    <b-col cols="12" sm="7">
                      <div class="client-information">
                        <div style="padding: 5px 15px">
                          <b-form-input
                            v-model="quotationData.client_to"
                            type="text"
                            class="mb-0"
                            placeholder="Quotation To:"
                          />
                          <b-form-input
                            v-model="quotationData.client_name"
                            type="text"
                            class="mb-0"
                            placeholder="Company: "
                          />
                          <b-form-input
                            v-model="quotationData.client_address1"
                            type="text"
                            class="mb-0"
                            placeholder="Address 1: "
                          />
                          <b-form-input
                            v-model="quotationData.client_address2"
                            type="text"
                            class="mb-0"
                            placeholder="Address 2: "
                          />
                        </div>
                      </div>
                    </b-col>
                    <!-- Header: Right Content -->
                    <b-col
                      class="quotation-number-date mt-md-0 mt-2"
                      cols="12"
                      sm="5"
                    >
                      <div class="mb-1">
                        <h4 class="quotation-title">Quotation</h4>
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title"> Inv No. 賬單編號 ： </span>
                        {{ quotationData.quotation_prefix }}
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title"> Ref. No. 客戶編號 ： </span>
                        {{ quotationData.client_no }}
                      </div>
                      <validation-provider
                        #default="validationContext"
                        name="Quotation Date"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <span class="title">
                            Date <span class="title-right">日期 ：</span>
                          </span>
                          <b-form-datepicker
                            id="quotation_date"
                            :state="getValidationState(validationContext)"
                            placeholder="Choose a date"
                            local="en"
                            v-model="quotationData.quotation_date"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }"
                          />
                        </div>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" sm="12" class="mt-1 client_contact">
                      <b-row>
                        <b-col cols="12" sm="6">
                          <b-form-group
                            label="Tel. 電話 ："
                            label-for="client_tel"
                            label-cols-md="3"
                          >
                            <b-form-input
                              v-model="quotationData.client_tel"
                              id="client_tel"
                              placeholder="Tel. 電話 ："
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                          <b-form-group
                            label="Fax 傳真 ： "
                            label-for="client_fax"
                            label-cols-md="3"
                          >
                            <b-form-input
                              v-model="quotationData.client_fax"
                              id="client_fax"
                              placeholder="Fax 傳真 ： "
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
                <!-- Spacer -->
                <hr />
                <b-card-body class="quotation-padding form-item-section">
                  <b-row class="mb-2">
                    <b-col cols="12" md="9">
                      <b-form-input
                        style="
                          font-style: italic;
                          font-size: 0.9em;
                          font-weight: 700;
                        "
                        v-model="quotationData.quotation_description"
                        id="quotation_description"
                        placeholder="quotation_description"
                      />
                    </b-col>
                    <b-col cols="12" md="3" class="quotation-item-amount">
                      <span class="text-right">Amount(HK$)</span>
                    </b-col>
                  </b-row>

                  <div
                    ref="form"
                    class="quotation-items repeater-form"
                    :style="{ height: trHeight }"
                  >
                    <b-row
                      v-for="(item, index) in quotationData.quotation_items"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >
                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col cols="12" lg="10"> Item </b-col>
                            <b-col cols="12" lg="2"> Price </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->

                            <b-col cols="12" lg="10">
                              <label class="d-inline d-lg-none">Item</label>
                              <v-select
                                v-model="item.service_code_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="servicecodes"
                                label="service_code"
                                :reduce="(option) => option.id"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Select Service Code"
                                @input="(val) => updateItemForm(index, val)"
                              />
                            </b-col>

                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Price</label>
                              <b-form-input
                                v-model="item.amount"
                                type="number"
                                class="mb-2"
                              />
                            </b-col>
                            <b-col cols="12" lg="10">
                              <label class="d-inline d-lg-none"
                                >Description</label
                              >
                              <b-form-textarea
                                v-model="item.description"
                                class="mb-2 mb-lg-0"
                              />
                            </b-col>
                          </b-row>
                          <div
                            class="
                              d-flex
                              flex-column
                              justify-content-between
                              border-left
                              py-50
                              px-25
                            "
                          >
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
                <!-- Items Section -->
                <b-card-body class="quotation-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                    :style="{ height: trHeight }"
                  ></div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm"
                  >
                    Add Item
                  </b-button>
                </b-card-body>
                <hr />
                <!-- Quotation Payment Method -->
                <b-card-body
                  class="quotation-padding pb-0 quotation-payment-method"
                >
                  <b-row>
                    <!-- Col: Sales Persion -->
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <span>{{ quotationData.bank_name_cn }}</span
                      ><span class="ml-3">{{ quotationData.bank_name_en }}</span>
                    </b-col>
                    <!-- Col: Total -->
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-6 d-flex justify-content-start"
                      order="1"
                      order-md="2"
                    >
                      <span
                        >銀行賬號 Bank Account：{{
                          quotationData.bank_account
                        }}</span
                      >
                    </b-col>
                  </b-row>
                </b-card-body>

                <!-- Spacer -->
                <hr />
                <b-card-body
                  class="quotation-padding pb-0 pt-0 quotation-payment-method"
                  v-if="quotationData.quotation_remarks"
                >
                  <b-row
                    v-for="item in quotationData.quotation_remarks"
                    :key="item.id"
                  >
                    <b-col cols="12" md="5" class="d-flex">
                      <div style="padding: 0 10px">*</div>
                      {{ item.remark_cn }}
                    </b-col>

                    <b-col cols="12" md="7" class="d-flex">
                      <div style="padding: 0 10px">*</div>
                      {{ item.remark_en }}
                    </b-col>
                  </b-row>
                </b-card-body>
                <hr />

                <!-- Note 
                        <b-card-body class="quotation-padding pt-0">
                          
                  <span class="font-weight-bold">Note: </span>
                  <b-form-textarea v-model="quotationData.note" />
                 
    
                        </b-card-body>
                        -->
                <!-- Footer & Payment Detail -->
                <b-card-body class="quotation-padding pt-0">
                  <b-row class="quotation-spacing">
                    <!-- Col: Quotation To -->
                    <b-col cols="12" xl="6" class="mb-lg-1 quotation_footer">
                      <h6 class="mb-2">
                        For and on behalf of<br />
                        {{ quotationData.company_name }}
                      </h6>

                      <div class="quotation_signature">
                        <hr />
                        <h6>Authorised Signature</h6>
                      </div>
                    </b-col>

                    <!-- Col: Payment Details -->
                    <b-col
                      xl="6"
                      cols="12"
                      class="mt-xl-0 mt-1 justify-content-end d-xl-flex d-block"
                    >
                      <div class="quotation-payment-detail">
                        <h6 class="mb-1">
                          <u>Payment Details</u>
                          <span class="ml-1">{{
                            quotationData.quotation_prefix
                          }}</span>
                        </h6>
                        <table>
                          <tbody>
                            <tr>
                              <td class="pr-1">Account received</td>
                              <td>:</td>
                              <td><span class="font-weight-bold"></span></td>
                            </tr>
                            <tr>
                              <td class="pr-1">Received on</td>
                              <td>:</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td class="pr-1">Bank / Cheque No.</td>
                              <td>:</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-form>
          </b-overlay>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="quotation-actions mt-md-0 mt-2">
          <!-- Action Buttons -->
          <b-card>
            <!-- Button: Send Quotation
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Send Quotation
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Preview
          </b-button>
             -->
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              @click="handleSubmit(onSubmit)"
            >
              Save
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              v-if="quotationData.client_id > 0"
              :to="{
                name: 'clients-detail',
                params: { id: quotationData.client_id },
              }"
            >
              Client Detail
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              :to="{ name: 'quotations-list' }"
            >
              Quotation List
            </b-button>
          </b-card>

          <!-- Select Client -->
          <b-card>
            <div class="mt-2">
              <validation-provider
                #default="validationContext"
                name="Client"
                rules="required"
              >
                <b-form-group label="Client" label-for="payment-client">
                  <b-form-input
                    style="display: none"
                    v-model="quotationData.client_id"
                    :state="getValidationState(validationContext)"
                    type="text"
                    class="mb-0"
                    placeholder="client_id"
                  />
                  <v-select
                    v-model="quotationData.client_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="client_code"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  >
                    <template slot="option" slot-scope="option">
                      [{{ option.client_code }}] {{ option.company_name_en }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div class="mt-2">
                <b-form-group label="Contract" label-for="contract_id">
                  <v-select
                    searchable
                    v-model="quotationData.contract_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contracts"
                    label="contract_id"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </div>
              <div class="mt-2">
                <b-form-group label="Project" label-for="project_id">
                  <v-select
                    v-model="quotationData.project_id"
                    searchable
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="projects"
                    label="project_id"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    ref="selectedProject"
                  />
                </b-form-group>
              </div>

              <div class="mt-2">
                <b-form-group label="Payment Terms" label-for="payment-method">
                  <v-select
                    searchable
                    v-model="quotationData.bank_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentaccounts"
                    label="description"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </div>

              <!-- ? Below values are not adding quotationData to keep quotationData more generic and less confusing  -->

              <!-- Payment Terms 
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Payment Terms</label>
            <b-form-checkbox
              id="paymentTerms"
              :checked="true"
              switch
            />
          </div>


          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
              id="clientNotes"
              :checked="true"
              switch
            />
          </div>


          <div class="d-flex justify-content-between align-items-center">
            <label for="paymentStub">Payment Stub</label>
            <b-form-checkbox
              id="paymentStub"
              switch
            />
          </div>
          -->
            </div>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BOverlay,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import quotationStoreModule from "../quotationStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, min } from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    BOverlay,
    BFormInvalidFeedback,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  // mounted() {
  //     this.initTrHeight()
  // },
  // created() {
  //     window.addEventListener('resize', this.initTrHeight)
  // },
  // destroyed() {
  //     window.removeEventListener('resize', this.initTrHeight)
  // },
  methods: {
    onSubmit() {
      console.log(this.quotationData);
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("quotation/addQuotation", this.quotationData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.clientData.id == 0) {
                // Return to client list
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.quotationData.quotation_items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      // this.$nextTick(() => {
      //     this.trAddHeight(this.$refs.row[0].offsetHeight)
      //     setTimeout(() => {
      //         this.$refs.form.style.overflow = null
      //     }, 350)
      // })
    },
    removeItem(index) {
      this.quotationData.quotation_items.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    // updateBankAccount(){
    //     var bank = this.paymentaccounts.filter(item => item.id == this.quotationData.bank_id)
    //     bank = bank[0]
    //     console.log(bank)
    //     this.quotationData.bank_name_cn = bank.bank_name_cn
    //     this.quotationData.bank_name_en = bank.bank_name_en
    //     this.quotationData.bank_account = bank.bank_account
    //     this.quotationData.quotation_remarks = bank.payment_remarks
    // }
  },
  computed: {
    client_id() {
      return this.quotationData.client_id;
    },
    bank_id() {
      return this.quotationData.bank_id;
    },
  },
  watch: {
    client_id() {
      this.quotationData.project_id = null;
      this.quotationData.contract_id = null;
      var client = this.clients.filter(
        (item) => item.id == this.quotationData.client_id
      );
      this.updateQuotationData(client);
    },
    bank_id() {
      this.updateBankAccount();
    },
  },
  setup() {
  const QUOTATION_STORE_MODULE_NAME = "quotation";
    const loading = ref(false);
    // Register module
  if (!store.hasModule(QUOTATION_STORE_MODULE_NAME))
    store.registerModule(QUOTATION_STORE_MODULE_NAME, quotationStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
    if (store.hasModule(QUOTATION_STORE_MODULE_NAME))
      store.unregisterModule(QUOTATION_STORE_MODULE_NAME);
    });

    const itemFormBlankItem = {
      item: null,
      service_code_id: 0,
      amount: 0,
      description: "",
    };

    const quotationData = ref({
      id: 0,
      client: null,
      quotation_date: "",
      company_name: "",
      company_name_cn: "",
      tcsp_licence_no: "",
      company_address_en: "",
      company_address_cn: "",
      company_tel: "",
      company_email: "",
      quotation_description: "",
      bank_name_en: "",
      bank_name_cn: "",
      bank_account: "",
      client_to: "",
      client_address1: "",
      client_address2: "",
      quotation_items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      quotation_remarks: [],
      quotation_prefix: "",
      project_id: null,
      contract_id: null,
      client_id: null,
    });

    if (router.currentRoute.name == "quotations-edit") {
      store
        .dispatch("quotation/fetchQuotation", { id: router.currentRoute.params.id })
        .then((response) => {
          quotationData.value = response.data.quotation;
          quotationData.value.bank_id = 1;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            quotationData.value = undefined;
          }
        });
    }

    const clients = ref([]);
    const paymentaccounts = ref([]);
    const servicecodes = ref([]);
    const settings = ref([]);
    const projects = ref([]);
    const contracts = ref([]);
    const temProjects = ref([]);
    const temContracts = ref([]);

    store
      .dispatch("quotation/fetchQuotationInformation")
      .then((response) => {
        clients.value = response.data.clients;
        paymentaccounts.value = response.data.paymentaccounts;
        servicecodes.value = response.data.servicecodes;
        settings.value = response.data.settings;
        console.log('settings.value', settings.value)
        temContracts.value = response.data.contracts;
        temProjects.value = response.data.projects;
        initialQuotationInformation();
        quotationData.value.bank_id = 1;
        updateBankAccount();

        if (router.currentRoute.params.client_id) {
          quotationData.value.client_id = router.currentRoute.params.client_id;
          var client = clients.value.filter(
            (item) => item.id == quotationData.value.client_id
          );
          updateQuotationData(client);
        }
        console.log('quotationData.value.client_id', quotationData.value.client_id)

      })
      .catch((error) => {
        console.log("error", error);
      });

    const initialQuotationInformation = () => {
      quotationData.value.company_name_en = getKeySet("company_name");
      quotationData.value.company_name_cn = getKeySet("company_name_cn");
      quotationData.value.tcsp_licence_no = getKeySet("tcsp_licence_no");
      quotationData.value.company_address_en = getKeySet("company_address_en");
      quotationData.value.company_address_cn = getKeySet("company_address_cn");
      quotationData.value.company_tel = getKeySet("company_tel");
      quotationData.value.company_fax = getKeySet("company_fax");
      quotationData.value.company_email = getKeySet("company_email");
      quotationData.value.quotation_description = getKeySet("quotation_description");
      quotationData.value.quotation_prefix = getKeySet("quotation_prefix");
    };

    const getKeySet = (key_set) => {
      var returnItem = settings.value.filter((item) => item.key_set == key_set);

      return returnItem[0].value;
    };

    const updateBankAccount = () => {
      var bank = paymentaccounts.value.filter(
        (item) => item.id == quotationData.value.bank_id
      );
      bank = bank[0];
      quotationData.value.bank_name_cn = bank.bank_name_cn;
      quotationData.value.bank_name_en = bank.bank_name_en;
      quotationData.value.bank_account = bank.bank_account;
      quotationData.value.quotation_remarks = bank.payment_remarks;
    };

    const updateItemForm = (index, val) => {
      var servicecode = servicecodes.value.filter((item) => item.id == val);
      servicecode = servicecode[0];
      const { cost, qty, description } = val;
      quotationData.value.quotation_items[index].description =
        servicecode.description2;
    };

    const updateQuotationData = (client) => {
      projects.value = [];
      contracts.value = [];
      projects.value = temProjects.value.filter(
        (item) => item.client_id == quotationData.value.client_id
      );
      contracts.value = temContracts.value.filter(
        (item) => item.client_id == quotationData.value.client_id
      );
      client = client[0];
      quotationData.value.client_to = client.contact_name;
      quotationData.value.client_name = client.company_name_en;
      quotationData.value.client_address1 = client.br_address;
      quotationData.value.client_tel = client.contact_phone;
      quotationData.value.client_fax = client.fax;
      projects.value.unshift({ id: null, project_id: " -- " });
      contracts.value.unshift({ id: null, contract_id: " -- " });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      quotationData,
      clients,
      paymentaccounts,
      servicecodes,
      settings,
      contracts,
      projects,
      temProjects,
      temContracts,
      updateBankAccount,
      updateQuotationData,
      updateItemForm,
      itemFormBlankItem,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.quotation-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;
    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
.logo-wrapper h3 span {
  color: black;
  font-size: 12px;
  font-weight: 100;
}

.logo-address {
  color: grey;
  font-weight: 100;
  font-size: 13px;
  font-style: italic;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;
    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;
    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.quotation-add .quotation-preview-card .quotation-title {
  font-size: 1.6em;
  margin-right: 0;
}

.quotation-add .quotation-number-date .title {
  font-size: 0.9em;
  width: 150px;
  position: relative;
}

.quotation-add .quotation-number-date .title-right {
  position: absolute;
  right: 16px;
}

.client-information {
  width: 95%;
  height: 4cm;
  position: relative;
}

.client-information:before,
.client-information:after,
.client-information > :first-child:before,
.client-information > :first-child:after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-color: #110f6a;
  /* or whatever colour */
  border-style: solid;
  /* or whatever style */
  content: " ";
}

.client-information:before {
  top: 0;
  left: 0;
  border-width: 1px 0 0 1px;
}

.client-information:after {
  top: 0;
  right: 0;
  border-width: 1px 1px 0 0;
}

.client-information > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 1px 1px 0;
}

.client-information > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 1px 1px;
}

.client-information .form-control {
  height: 35px;
}

.quotation_footer {
  height: 3.8cm;

  position: relative;
}

.quotation_footer h6 {
  font-weight: 100;
  font-size: 0.9em;
}

.quotation_signature {
  position: absolute;
  bottom: 0;
  width: 60%;
}

.quotation_signature hr {
  border-top: 1px solid grey;
}
.quotation-payment-detail {
  width: 7.5cm;
  height: 3.4cm;
  border: 1px solid grey;
  padding: 0.36cm 0.5cm;
  font-size: 0.8em;
}
.quotation-payment-detail tr {
  height: 0.6cm;
}

.quotation-payment-method {
  font-size: 0.85em;
  font-weight: 500;
  padding: 0 35px 0;
}
.quotation-items {
  // min-height:13.4cm;
  //border:1px solid #dde3ed;
}
.quotation-item-amount {
  position: relative;
  text-align: right;
}
.quotation-item-amount span {
  font-size: 0.9em;
  font-weight: 700;
}

// Fix dropdown-menu
.dropdown-menu {
  z-index: 10030 !important;
}
</style>
